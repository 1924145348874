#inventoryModalShadow {
  z-index: 3;
  background-color: #0000004d;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#inventoryModalContent {
  z-index: 4;
  box-sizing: border-box;
  background-color: #f5f5f5;
  border: 2px solid #333;
  justify-content: space-between;
  width: 80%;
  max-width: 800px;
  height: 80%;
  padding: 20px;
  display: flex;
  position: fixed;
  top: 10%;
  left: 50%;
  overflow: auto;
  transform: translateX(-50%);
}

.inventory-header {
  margin-bottom: 10px;
}

.inventory-items {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.inventory-item {
  box-sizing: border-box;
  background-color: #f5f5f5;
  border: 2px solid #333;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100px;
  padding: 10px;
  font-size: .8em;
  display: flex;
}

.inventory-item-image {
  background: #708090;
  width: 80px;
}

.inventory-thaddeus-image {
  width: 150px;
}

.inventory-section {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  display: flex;
}

#itemsSection {
  align-items: start;
}

#goldSection {
  align-items: center;
  gap: 10px;
  display: flex;
}

.gold-icon {
  width: 30px;
}

#equipmentSection {
  margin-top: 50px;
}

.close-inventory {
  cursor: pointer;
  border: 2px solid #333;
  padding: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-inventory:hover {
  color: #f5f5f5;
  background-color: #333;
}

@media only screen and (width <= 580px) {
  #inventoryModalContent {
    flex-direction: column-reverse;
    justify-content: start;
  }

  #equipmentSection {
    align-items: start;
    margin-top: 0;
  }

  .inventory-thaddeus-image {
    display: none;
  }
}
/*# sourceMappingURL=index.e326c282.css.map */
